<template>
  <v-container class="layout-container pa-0">
    <v-card
      outlined
      class="pa-4"
      style="overflow:hidden"
    >
      <v-form
        ref="form"
        v-model="isValid"
      >
        <v-row>
          <v-col
            v-if="$vuetify.breakpoint.smAndUp"
            sm="3"
          >
            <div class="design-wrapper">
              <Leaf
                class="design-elements"
                width="1000"
                style="top:-1200px; right: 100px; opacity: 0.2"
                fill
              />

              <SingleCircle
                class="design-elements"
                width="100"
                style="top:10px; right: 150px; opacity: 0.8"
                stroke
                :stroke-width="1"
              />

              <SingleCircle
                class="design-elements"
                width="100"
                style="top:10px; right:130px; opacity: 0.3"
                fill
              />

              <SingleCircle
                class="design-elements"
                width="30px"
                style="top: 130px; right: 210px; opacity: 0.8"
                stroke
              />

              <SingleCircle
                class="design-elements"
                width="30px"
                style="top: 130px; right: 200px; opacity: 0.3"
                fill
              />

              <SingleCircle
                class="design-elements"
                width="30px"
                style="top: 130px; right: 160px; opacity: 0.8"
                stroke
              />

              <SingleCircle
                class="design-elements"
                width="30px"
                style="top: 130px; right: 150px; opacity: 0.3"
                fill
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="9"
            class="pa-6"
          >
            <v-row>
              <v-col>
                <p>
                  Erstellen Sie mit wenigen Klicks ein neues Büro
                  und ermöglichen Sie dem Büroeigentümer das Ausfüllen weiterer Daten.
                </p>
              </v-col>
            </v-row>
            <!-- Company data -->
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="company.slug"
                  outlined
                  hide-details
                  label="Eindeutiges Firmenkürzel"
                  placeholder="max-mustermann-buero-berlin"
                  :rules="[rules.required]"
                  required
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="company.country"
                  outlined
                  :items="countries"
                  hide-details
                  label="Länderkennung"
                  item-value="key"
                  item-text="text"
                  :rules="[rules.required]"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="text-h6 font-weight-regular">Bürokontakt</span>
                <p>Für diesen Kontakt wird ein Nutzer im System angelegt. Dieser erhält eine E-Mail zur Registrierung und ist anschließend bemächtigt, die benötigten Firmendaten zu ergänzen.</p>
              </v-col>
            </v-row>
            <!-- User data -->
            <v-row>
              <v-col cols="12">
                <User
                  :user="user"
                  @updateUser="updateUser"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  :disabled="!isValid"
                  :class="{'w-full':$vuetify.breakpoint.smAndDown}"
                  color="primary"
                  :loading="isSubmitting"
                  large
                  @click="validate"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>
                  Hinzufügen
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { required } from '@/lib/validation'
import bus, { eventNames } from '@/lib/eventBus'
import Leaf from '@/components/design-elements/leaf.vue'
import CREATE_SUB_COMPANY from './CreateSubCompany.gql'
import CREATE_USER from '@/modules/company/queries/CreateUser.gql'
import SingleCircle from '@/components/design-elements/singleCircle.vue'
import User from './User.vue'

export default {
  components: { Leaf, SingleCircle, User },
  data () {
    return {
      isValid: false,
      isSubmitting: false,
      company: {
        slug: '',
        country: '',
        variant: 'SHOWCASE'
      },
      user: {
        firstname: '',
        lastname: '',
        email: '',
        salutation: ''
      },
      rules: {
        required
      },
      countries: [
        { text: 'Deutschland', key: 'de' },
        { text: 'Österreich', key: 'at' },
        { text: 'Schweiz', key: 'ch' }
      ]
    }
  },
  methods: {
    updateUser (user) {
      this.user = user
    },
    async validate () {
      await this.$refs.form.validate()

      if (!this.isValid) return

      try {
        this.isSubmitting = true

        const { data: { company } } = await this.$apollo.mutate({
          mutation: CREATE_SUB_COMPANY,
          variables: {
            input: {
              slug: this.company.slug,
              country: this.company.country,
              variant: this.company.variant,
              productType: 'SHOWCASE',
              corporateCompanyId: this.$auth.user.companyId
            }
          }
        })

        await this.$apollo.mutate({
          mutation: CREATE_USER,
          variables: {
            input: {
              companyId: company.id,
              salutation: this.user.salutation,
              firstname: this.user.firstname,
              lastname: this.user.lastname,
              email: this.user.email,
              receiveNotificationEmails: {
                leadAction: true,
                newsletter: true
              }
            }
          }
        })

        this.reset()

        this.$router.push({
          path: '/dashboard'
        })

        this.isSubmitting = false
      } catch (error) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Probleme beim Hinzufügen des Büros. Bitte versuchen Sie es erneut.' })
        this.isSubmitting = false
      }
    },
    reset () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Büro hinzugefügt.' })
    }
  }

}
</script>

<style scoped>
.design-wrapper {
  position: relative;
  width:300px;
  height: 1px;
}

.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
</style>
