<template>
  <v-row
    class="d-flex"
  >
    <v-col
      cols="12"
      md="2"
    >
      <v-select
        v-model.lazy="userData.salutation"
        outlined
        hide-details
        label="Anrede"
        :items="salutations"
        :rules="[rules.required]"
      />
    </v-col>
    <v-col
      cols="12"
      md="5"
    >
      <v-text-field
        ref="billing.firstname"
        v-model.lazy="userData.firstname"
        outlined
        hide-details
        label="Vorname"
        :rules="[rules.required]"
      />
    </v-col>
    <v-col
      cols="12"
      md="5"
    >
      <v-text-field
        ref="billing.lastname"
        v-model.lazy="userData.lastname"
        outlined
        hide-details
        label="Nachname"
        :rules="[rules.required]"
      />
    </v-col>
    <v-col
      cols="12"
    >
      <v-text-field
        ref="email"
        v-model.lazy="userData.email"
        outlined
        hide-details
        label="E-Mail"
        :rules="[rules.email, rules.required]"
      />
    </v-col>
  </v-row>
</template>

<script>
import { SALUTATIONS } from '@/components/forms/labels'
import { email, required } from '@/lib/validation'

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      userData: { ...this.user },
      salutations: SALUTATIONS,
      rules: { email, required }
    }
  },
  watch: {
    userData: {
      deep: true,
      handler () {
        this.$emit('updateUser', this.userData)
      }
    }
  }

}
</script>

<style>

</style>
