<template>
  <v-container class="layout-container">
    <BaseHeader
      title="Büro hinzufügen"
      :style-variant="18"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>Hier können Sie schnell und in wenigen Schritten ein neues Büro hinzufügen.</p>
      </template>
    </BaseHeader>
    <v-col
      cols="12"
      class="pa-0"
    >
      <CreateCompany />
    </v-col>
  </v-container>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue'
import CreateCompany from '@/modules/corporateCompany/createCompany'
import HeaderDesign from '@/modules/corporateCompany/createCompany/HeaderDesign.vue'

export default {
  components: {
    BaseHeader,
    CreateCompany,
    HeaderDesign
  }
}
</script>
